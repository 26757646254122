@import '../../../styles/customMediaQueries.css';

.root {
  composes: marketplaceModalBaseStyles from global;
  padding-top: 36px;

  @media (--viewportMedium) {
    flex-basis: 567px;
    padding: 36px 41px 32px 41px;
    box-shadow: 0px 4px 4px 0px #00000040;
    border-bottom: 0 !important;
  }
}

.modalContent {
  flex-grow: 1;
}

/* Icon of the modal */
.modalIcon {
  composes: marketplaceModalIconStyles from global;
}

/* Title of the modal */
.modalTitle {
  composes: marketplaceModalTitleStyles from global;
  font-size: 32px;
}

.reviewee {
  white-space: nowrap;
}

/* Paragraph for the Modal */
.modalMessage {
  composes: marketplaceModalParagraphStyles from global;
}

.avatarWrapper {
  margin-top: 0px;
}

.nameWrapper {
  color: #6e6e73;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px; /* 150% */
  letter-spacing: -0.2px;
  padding-top: 0px;
}

.reviewerInfo {
  display: flex;
  align-items: center;
  gap: 6px;
  margin-top: 40px;
  & a {
    margin-top: 0;
    width: 55px;
    height: 55px;
  }
}

.starRatings {
  margin-top: 10px;
}